import checkIcon from '../../assets/checkIcon.png';
import werkszertifikat from '../../assets/werkszertifikat.png';
import { GeneralTexts, OtherTexts } from "../../language/Texts";
import AccordionComponent from '../accordionComponent/AccordionComponent';
import SectionTitle from '../sectionTitle/SectionTitle';
import './CertificateComponent.scss';

import ASME from '../../assets/ASME.png';
import ATEX from '../../assets/ATEX.png';
import CML from '../../assets/CML.png';
import DGRL from '../../assets/DGRL.png';
import EHEDG from '../../assets/EHEDG.png';
import FM from '../../assets/FM.png';
import GL from '../../assets/GL.png';
import KOSHA from '../../assets/KOSHA.png';
import NFPA from '../../assets/NFPA.png';
import CE from '../../assets/CE.png';
import USDA from '../../assets/USDA.png';
import { IAttribute } from '../../models/IOutputData';

interface CertificateProps {
    attributes: IAttribute[] | undefined;
}

function CertificateComponent({ attributes }: CertificateProps) {
    const certificateImages: { [key: string]: string } = {
        ASME: ASME,
        ATEX: ATEX,
        CML: CML,
        NFPA: NFPA,
        FM: FM,
        EHEDG: EHEDG,
        GL: GL,
        KOSHA: KOSHA,
        USDA: USDA,
        CE:CE
    };

    const componentBody =
        <div className='certificate_container'>
            <div className="self_certificate_wrapper">
                <img className="certificate_image" src={werkszertifikat} alt="certificate" />
                <span className="certificate_text">{OtherTexts.worksCertificate}</span>
            </div>
            {attributes?.map((attribute, index) => {
                const certificateImage = certificateImages[attribute.title];
                return (
                    <div key={index} className="self_certificate_wrapper">
                        <img className="certificate_image" src={certificateImage} alt="certificate" />
                        <span className="certificate_text">{attribute.value}</span>
                    </div>
                )
            })}
        </div>

    const titleComponent =
        <SectionTitle
            icon={<img className="section_title_icon" src={checkIcon} />}
            title={GeneralTexts.certificates} />

    return (
        <AccordionComponent
            title={titleComponent}
            body={componentBody}
        />
    )
}

export default CertificateComponent
