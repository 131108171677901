import rembeLogo from '../../assets/rembeLogo.png'
import { GeneralTexts, OtherTexts } from '../../language/Texts'
import './Footer.scss'

function Footer() {
    const footerLinks = [
        {
            name: OtherTexts.rembeWebsite.replace('http://', ''),
            url: OtherTexts.rembeWebsite
        },
        {
            name: GeneralTexts.imprint,
            url: OtherTexts.imprintUrl
        },
        {
            name: GeneralTexts.dataSecurity,
            url: 'https://rembe.de/datenschutzerklaerung'
        },
        {
            name: GeneralTexts.termOfUse,
            url: 'https://rembe.de/agb'
        },
        {
            name: 'powered by conexo',
            url: 'https://inevvo-solutions.com/'
        }
    ]

    const linkList = () => footerLinks.map((item, index) => <a key={index} className='footer_link' target='_blank' href={item.url}>{item.name}</a>)

    return (
        <div className='footer_wrapper'>
            <div className="links_wrapper">
                {linkList()}
            </div>
            <div className="footer_logo_text_wrapper">
                <a href={OtherTexts.rembeWebsite} target='_blank'>
                    <img className='footer_logo' src={rembeLogo} alt="rembeLogo" />
                </a>
                <div className="logo_text">© REMBE® GmbH Safety+Control </div>
            </div>

        </div>
    )
}

export default Footer
