import { FaCheckCircle } from "react-icons/fa";
import { GeneralTexts } from "../../language/Texts";
import { CommonComponentProps } from "../../models/CommonComponentProps";
import { EmailBodyType } from "../../models/EmailBodyType.enum";
import { getMailBody, openEmailSender } from "../../utils/EmailSender";
import "./General.scss";

function General({ articleDescriptionS, generalAttributeList }: CommonComponentProps) {

    const getItemAttribute = (key: string, value) => {
        return (
            <div className="item_wrapper">
                <div className="general_item_container">
                    <div className="general_item_key">
                        {key}
                    </div>
                </div>
                <div className="general_item_container">
                    {value}
                </div>
            </div>
        )
    }
    const manufacturerItem = <>  {generalAttributeList.manufacturer}  <FaCheckCircle color="#AFCB37" /></>
    let mailSubject: string = `${GeneralTexts.reorder}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate}`

    if (generalAttributeList.customerOrderNumber) {
        mailSubject = `${GeneralTexts.reorder}: ${articleDescriptionS} - ${GeneralTexts.digitalTagPlate} | ${generalAttributeList.customerOrderNumber}`
    }

    const mailBody = getMailBody(generalAttributeList, EmailBodyType.REORDER)

    const attributes = ['tid', 'manufacturer', 'componentType', 'batchNumber', 'serialNumber', 'customerOrderNumber', 'orderNumber', 'articleNumberExt', 'productInfo'];
    return (
        <div className="general_wrapper">
            <div
                data-testid="product_reorder"
                onClick={() => openEmailSender(mailSubject, mailBody)}
                className="general_title"
            >{GeneralTexts.generalSectionTitle}</div>

            <div className="key_value_wrapper">
                {generalAttributeList.tid && getItemAttribute(GeneralTexts.tid, generalAttributeList.tid)}
                {generalAttributeList.manufacturer && getItemAttribute(GeneralTexts.manufacturer, manufacturerItem)}
                {generalAttributeList.componentType && getItemAttribute(GeneralTexts.articleDesignation, generalAttributeList.componentType)}
                {generalAttributeList.batchNumber && getItemAttribute(GeneralTexts.batchNumber, generalAttributeList.batchNumber)}
                {generalAttributeList.serialNumber && getItemAttribute(GeneralTexts.serialNumber, generalAttributeList.serialNumber)}
                {generalAttributeList.customerOrderNumber && getItemAttribute(GeneralTexts.ourOrderNumber, generalAttributeList.customerOrderNumber)}
                {generalAttributeList.orderNumber && getItemAttribute(GeneralTexts.orderConfirmation, generalAttributeList.orderNumber)}
                {generalAttributeList.articleNumberExt && getItemAttribute(GeneralTexts.type, generalAttributeList.articleNumberExt)}
                {generalAttributeList.productInfo && getItemAttribute(GeneralTexts.productInfo, generalAttributeList.productInfo)}
            </div>
        </div>
    );
}

export default General;
