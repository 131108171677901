import documentIcon from '../../assets/documentIcon.png';
import { GeneralTexts } from '../../language/Texts';
import { IDocument } from '../../models/IDocument';
import AccordionComponent from '../accordionComponent/AccordionComponent';
import SectionTitle from '../sectionTitle/SectionTitle';
import './ProductDocuments.scss';

interface DocumentProps {
  documentList: IDocument[]
}

function ProductDocuments({ documentList }: DocumentProps) {

  const componentBody =
    <div className="document_list">
      {documentList?.map((document, index) => {
        return (
          <a
            key={index}
            className="doc_link"
            href={document.link}
            rel="noreferrer"
            type={document.mimeType}
            target="_blank"
            data-testid="document_link"
          >
            {document.title}
          </a>
        )
      })
      }
    </div>

  const titleComponent =
    <SectionTitle
      icon={<img className="section_title_icon" src={documentIcon} />}
      title={GeneralTexts.currentProductDocumentation} />

  return (
    <>
      {documentList.length > 0 &&
        <AccordionComponent
          title={titleComponent}
          body={componentBody}
        />
      }
    </>
  )
}

export default ProductDocuments

